//This document uses camelcase to denominate all variables, if a category(object) has multiple of the same thing, it goes in the plural form
//open-ended phrases must have a space at the end.
//The only exception to this rules is that I couldn't stop you
//so you could do what the fuck you want
// 🙈

export default {
  companyName: 'PartnersFans',
  followers: 'Seguidores',
  store: 'Tienda',
  card: 'Tarjeta',
  billingAddress: 'Dirección de facturación',
  fairs: {
    stores: 'Tiendas inscriptas',
    accepted_stores: 'Tiendas aprobadas',
    rejected_stores: 'Tiendas rechazadas',
    commission: 'Comisión total',
    orders: 'Órdenes totales',
    finished_orders: 'Órdenes finalizadas',
    traffic: 'Cantidad de visitantes'
  },
  versions: {
    desktop: 'escritorio',
    mobile: 'celulares'
  },
  sections:{
    shippingMethodAndAddress: 'Dirección y método de envío',
    resume: 'Resumen',
    billing: 'Facturación',
    products:'Productos'
  },
  address: {
    street: 'Calle',
    number: 'Número',
    unit: 'Departamento / unidad',
    city: 'Ciudad',
    state: 'Región',
    neighbourhood: 'Comuna',
    country: 'País',
    fullname: 'Nombre y apellido',
    idNumber: 'RUT',
    phone: 'Número de teléfono',
    phone_prefix: '+56',
    zipcode: 'Código postal'
  },
  actions: {
    login: 'Iniciar sesión',
    explore: 'Explorar',
    signup: 'Crear cuenta',
    next: 'Continuar',
    notSignUp: '¿No tienes cuenta?',
    forgetPassword: '¿Olvidaste tu contraseña?',
    facebookLogin: 'Iniciar sesión con Facebook',
    googleLogin: 'Iniciar sesión con Google',
    search: 'Buscar',
    follow: 'Seguir',
    newAddress: 'Nueva dirección',
    editAddress: 'Editar dirección',
    save: 'Guardar',
    cancel:'Cancelar',
    pickYourShippingMethod: '¡Elige tu método de envío!',
    newCard: 'Nueva Tarjeta',
    editCard: 'Editar Tarjeta',
    addBillingInfo: 'Usar otra dirección de facturación',
    brightness:'Brillo',
    saturation: 'Saturación',
    contrast: 'Contraste',
    reset: 'Restaurar'
  },
  errors: {
    errors: '¡Hay un error en tu email y/o tu contraseña! ',
    invalid_code: 'Código de validación inválido',
    code_max_tries_exceeded: 'Se superó el número máximo de intentos.',
    email_has_already_been_taken: 'Ya existe una cuenta asociada a este correo electrónico.',
    user_not_found: 'Usuario no encontrado',
    password_did_not_match: 'Contraseña incorrecta',
    sku_taken: 'SKU ya está en uso'
  },
  fields: {
    product: {
      height: 'Alto (centímetros)',
      length: 'Largo (centímetros)',
      depth: 'Ancho (centímetros)',
      weight: 'Peso (gramos)'
    },
    postType: 'Tipo de publicación',
    body: 'Descripción',
    picture: 'Foto',
    name: 'Nombre y apellido',
    email: 'Email',
    password: 'Contraseña',
    firstname: 'Nombre',
    lastname: 'Apellido',
    fullname: 'Nombre y apellido',
    myAddresses: 'Mis direcciones',
    myCards: 'Mis tarjetas',
    title: 'Alias',
    street: 'Calle',
    number: 'Número',
    appartment: 'Departamento',
    floor: 'Piso',
    cp: 'Código postal',
    city:'Ciudad',
    comments:'Comentarios',
    expirationDate: 'Fecha de vencimiento',
    securityCode: 'Código de seguridad'
  },
  posts: {
    types: {
      // snake_case since it needs to match database fields jasdja sorry
      standard: 'Tradicional',
      want_it: '😍 ¡Lo quiero!',
      love_it: '😍 ¡Me encanta!',
      recommend_it: '🤩 ¡Lo recomiendo!',
      looking_for_it: '🤔 ¡Lo estoy buscando!',
    }
  },
  profiles: {
    actions: {
      standard: 'Tradicional',
      want_it: '😍 ¡Lo quiero!',
      love_it: '😍 ¡Me encanta!',
      recommend_it: '🤩 ¡Lo recomiendo!',
      looking_for_it: '🤔 ¡Lo estoy buscando!',
    }
  },
  object: {
    edit: {
      success: 'Edición exitosa.'
    }
  },
  states: {
    cart: 'Carrito',
    completed: 'Completado',
    address: 'Dirección',
    shipping: 'Selección de envío',
    payment: 'Selección de pago',
  },
  stores: {
    store: 'bodega',
    iWantToBe: 'Quiero ser ',
    admin:{
      profileType: 'Tipo de perfil',
      mainBanner: 'Banner principal',
      profileType1: 'Foto pequeña',
      profileType2: 'Foto grande'
    }
  },
  business: {
    business: 'comercio',
    iHaveA: 'Tengo un ',
  },
  carriers: {
    carrier: 'transportista',
    iAm: 'Soy un '
  },
  shipments: {
    state: {
      pending: 'Pendiente',
      ready: 'A despachar',
      shipped: 'En camino',
    }
  },
  validation: {
    is_required: 'Campo obligatorio',
    must_be_valid_email: 'Debe ser un e-mail válido'
  },
  transbank: {
    rut: 'RUT',
    rutRep: 'RUT representante legal',
    serialNumber: 'Número de documento/serie carnet representante legal',
    fantasyName: 'Nombre de fantasía',
    phone: 'Teléfono',
    email: 'Correo electrónico',
    address: 'Dirección',
    bank: 'Banco',
    bankAccountType: 'Tipo de cuenta',
    bankAccountNumber: 'Número de cuenta',
  }
}

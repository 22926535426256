import { OrderService } from '@/services/storageService'
import { apolloClient } from '@/graphql/apollo'
import { Order } from '@/graphql/queries/orders'

export default {
  namespaced: true,
	state: {
		currentOrder: null,
    preferenceId: null,
    initPoint: null,
		url: process.env.VUE_APP_RAILS_URL,
	},
	getters: {
		currentOrder: state => {
			return state.currentOrder
		},
		url: state => {
			return state.url
		},
    preferenceId: state => {
      return state.preferenceId
    },
    initPoint: state => {
      return state.initPoint
    }
	},
	mutations: {
		setOrder (state) {
      apolloClient.query({
        query: Order,
        variables: {
          orderToken: OrderService.getToken()
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        state.currentOrder = res.data.order
      })
		},

    setPreferenceId (state, preferenceId) {
      state.preferenceId = preferenceId
    },

    setInitPoint (state, initPoint) {
      state.initPoint = initPoint
    },
	}
}


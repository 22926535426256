import Vue from "vue";
import MainApp from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store/index";
import vuetify from "./plugins/vuetify";
import VueI18n from "vue-i18n";
import messages from "./i18n";
import "@/style/application.sass";
import wysiwyg from "vue-wysiwyg";
import Vue2TouchEvents from "vue2-touch-events";
import OneSignal from "onesignal-vue";
Vue.use(OneSignal);

import { apolloProvider } from "./graphql/apollo";

import { Plugins } from "@capacitor/core";
const { App } = Plugins;

const { SplashScreen } = Plugins;

Vue.use(wysiwyg, {
  hideModules: {
    image: true,
    link: true,
    code: true,
    table: true,
    separator: true,
  },
  locale: "hu",
});

Vue.use(Vue2TouchEvents, {
  namespace: "hammer",
});

Vue.use(VueI18n);
Vue.prototype.$activeAppbar = true; //global instace property

const i18n = new VueI18n({
  locale: "es",
  fallbackLocale: "es",
  silentFallbackWarn: true,
  messages,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  apolloProvider,
  vuetify,
  render: (h) => h(MainApp),
  mounted() {
    SplashScreen.hide();
  },
}).$mount("#app");

App.addListener("appUrlOpen", function (data) {
  // Example url: https://beerswift.app/tabs/tabs2
  // slug = /tabs/tabs2
  const slug = data.url.split(".cl").pop();

  // We only push to the route if there is a slug present
  if (slug) {
    router.push({
      path: slug,
    });
  }
});

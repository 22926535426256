<template>
  <v-app>
    <navbar v-if="$route.meta.admin"></navbar>
    <owners-navbar v-if="$route.meta.owners"></owners-navbar>
    <appbar 
      v-if="(!$route.meta.hideAppbar && !($vuetify.breakpoint.mobile && ['profile'].includes($route.name)))"></appbar>

    <v-main>
      <router-view>
      </router-view>

      <v-dialog
        v-model="modal"
        max-width="600px"
        scrollable
        persistent
        >
        <onboarding
          @reload="reload"
          ></onboarding>
      </v-dialog>

      <join-fair
        :key="band"
        ></join-fair>
    </v-main>

    <v-snackbar
      light
      bottom
      center
      v-model="subscription"
      :timeout="-1"
      >
      <div>
        <div
          class="mb-3 body-1 d-flex flex-column flex-md-row align-center"
          style="gap: 12px"
          >
          <v-icon
            color="primary"
            large
            >
            mdi-bell
          </v-icon>
          <div
            class="text-center text-md-left"
            >
            ¿Deseas recibir notificaciones sobre tus compras, ventas e interacciones? <br/> No te preocupes, puedes cambiar esta configuración cuando quieras.
          </div>
        </div>
        <div
          class="d-flex align-center justify-center justify-md-end"
          style="gap: 8px"
          >
          <v-btn
            outlined
            small
            color="primary"
            @click="subscribe(false)"
            >
            Quizás luego
          </v-btn>
          <v-btn
            depressed
            color="primary"
            @click="subscribe"
            >
            ¡Claro que sí!
          </v-btn>
        </div>
      </div>
    </v-snackbar>

    <bottom-navigation v-if="!$route.meta.hideBottom && !$vuetify.breakpoint.lgAndUp"></bottom-navigation>
  </v-app>
</template>

<script>
import OneSignal from "onesignal-vue";

import { SignIn, UpdateCurrentSubscription } from '@/graphql/mutations/users'
import { mapGetters } from 'vuex'

import appbar from '@/components/bars/Appbar.vue';
import bottomNavigation from '@/components/bars/BottomNavigation.vue';

const OwnersNavbar = () => import(/* webpackPrefetch: true */ "@/components/bars/OwnersNavbar");
const Navbar = () => import(/* webpackPrefetch: true */ "@/components/bars/Navbar");
const Onboarding = () => import('@/components/shared/onboarding/Onboarding');
const JoinFair = () => import('@/components/fairs/Join');

export default {
  name: 'App',

  data () {
    return {
      modal: false,
      subscription: false,
      band: 0
    }
  },

  watch: {
    currentUser (val) {
      this.modal = (val || {}).firstSignIn
    },

    "$route.hash": {
      handler(value){
        if(value == "#change_profile"){
          this.signIn()

          this.$router.push({ hash: '' })
        }
      }
    },

    "$route.name": {
      handler (val) {
        if (!['profile', 'fair'].includes(val))
          localStorage.removeItem('orderFairId')
      }
    }
  },

  components: {
    appbar, bottomNavigation, Onboarding, Navbar, OwnersNavbar, JoinFair
  },

  computed: {
    ...mapGetters(['currentUser', 'isLoggedIn'])
  },

  created () {
    if (!['profile', 'fair'].includes(this.$route.name))
      localStorage.removeItem('orderFairId')

    if (!this.currentUser && this.isLoggedIn) {
      this.signIn()

      this.$OneSignal.on('subscriptionChange', (res) => {
        console.log('inside subscription change:', res)
        if (res) {
          this.$OneSignal.getUserId( (id) => {
            console.log('inside getuser:', res)
            this.updateCurrentSubscription(id, res)
          });
        }
      })
    }
  },

  methods: {
    subscribe (band = true) {
      if (band) {
        this.$OneSignal.registerForPushNotifications()
      } else {
        this.updateCurrentSubscription (null, false, 'deny')
      }
    },

    updateCurrentSubscription (id, status, opts = null) {
      this.$apollo.mutate({
        mutation: UpdateCurrentSubscription,
        variables: {
          input: {
            id: id,
            status: status,
            opts: opts
          }
        },
        fetchPolicy: 'no-cache'
      }).then(res => {
        this.subscription = false
      })
    },

    reload () {
      this.band += 1
    },

    signIn () {
      this.$apollo.mutate({
        mutation: SignIn,
        variables: {
          input: {
            token: localStorage.getItem('auth_token')
          }
        },
        fetchPolicy: 'no-cache'
      }).then(res => {
        let data = res.data.signIn

        if (data.token) {
          localStorage.setItem('auth_token', data.token)
          localStorage.setItem('prefetched_sub', data.user.selectedProfile.id)
          this.$store.commit("setCurrentUser", data.user)

          this.$OneSignal.getUserId( res => {
            console.log('here')
            if (data.user.onesignalSubscribed) {
              this.subscribe()
            } else if (!(data.user.onesignalStatus == 'deny')) {
              this.subscription = true
            }
          })
        } else {
          localStorage.removeItem('auth_token')
        }
      }).catch(errors => {
        localStorage.removeItem('auth_token')
        this.$router.go()
      })
    }
  }

}
</script>

<style>
.onesignal-customlink-explanation, .onesignal-customlink-explanation.onesignal-reset {
  color: white !important
}
</style>

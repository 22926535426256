<template>
  <v-app-bar
    app
    flat
    dense
    color="white"
    style="border-bottom: 1px solid #d2d2d2 !important;"
  >
    <v-toolbar-title
      class="font-weight-medium text-h5 d-flex flex-row fill-width align-center justify-space-between"
      :class="$route.name != 'chats' ? 'justify-center' : ''"
    >
      <router-link :to="{ name: 'home' }" custom v-slot="{ navigate }">
        <div style="width: 210px" class="d-flex align-center" @click="navigate">
          <v-icon
            v-if="$route.meta.back"
            @click.stop="$router.go(-1)"
            class="mr-2"
          >
            mdi-chevron-left
          </v-icon>

          <template v-if="$route.meta.title">
            {{ $route.meta.title }}
          </template>

          <v-carousel
            v-else
            height="30"
            hide-delimiters
            :show-arrows="false"
            cycle
            continuous
            interval="3000"
            reverse
          >
            <v-carousel-item
              width="130"
              contain
              :src="require('@/assets/img/logo_01.png')"
              position="center left"
            ></v-carousel-item>
            <v-carousel-item
              width="130"
              contain
              position="center left"
              :src="require('@/assets/img/tipografica.png')"
            ></v-carousel-item>
          </v-carousel>
        </div>
      </router-link>

      <div
        class="px-3"
        style="width: 400px"
        v-if="currentUser && $vuetify.breakpoint.mdAndUp"
      >
        <search appbar></search>
      </div>

      <div
        class="shrink text-right d-flex align-center"
        v-if="currentUser"
        style="gap: 4px"
      >
        <template v-if="!$vuetify.breakpoint.mobile">
          <Dialog color="secondary" icon small></Dialog>

          <v-btn to="/" small icon color="secondary">
            <v-icon>
              mdi-home
            </v-icon>
          </v-btn>

          <v-btn to="/explore" small icon color="secondary">
            <v-icon>
              mdi-compass
            </v-icon>
          </v-btn>

          <v-btn to="/checkout" small icon color="secondary">
            <v-icon>
              mdi-cart
            </v-icon>
          </v-btn>
        </template>

        <notifications></notifications>

        <v-btn to="/chats" small icon color="secondary">
          <v-icon color="secondary">
            mdi-inbox
          </v-icon>
          <div
            style="width: 16px; height: 16px; position: absolute; left: 0; bottom: 0"
            class="primary white--text d-flex justify-center align-center rounded-circle"
            v-if="unreadChats"
            >
            {{ unreadChats }}
          </div>
        </v-btn>

        <v-menu 
          offset-y
          nudge-left="49"
          nudge-bottom="9"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-if="!$vuetify.breakpoint.mobile"
              v-on="on"
              v-bind="attrs"
              >
              <v-avatar size="28">
                <v-img
                  :src="url + currentUser.selectedProfile.picture.thumbUrl"
                  v-if="currentUser.selectedProfile.picture"
                  ></v-img>
                <v-icon v-else>
                  mdi-account-circle
                </v-icon>
              </v-avatar>
            </v-btn>
          </template>

          <v-list
            >
            <v-list-item
              :to="'/p/' + currentUser.selectedProfile.slug"
              active-class="primary--text"
              >
              <v-list-item-title>Mi perfil</v-list-item-title>
            </v-list-item>

            <v-list-item
              @click="logout"
              active-class="primary--text"
              >
              <v-list-item-title>Cerrar sesión</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div
        v-else
        >
        <v-btn
          v-if="!isLoggedIn"
          small
          color="secondary"
          depressed
          outlined
          :to="{ name: 'signup' }"
          >
          ¡Regístrate gratis ahora!
        </v-btn>
      </div>
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import { UnreadChats } from "@/graphql/subscriptions/chats";

const Dialog = () => import("@/components/posts/Create");
const Notifications = () => import("@/components/notifications/Notifications");
const Search = () => import("@/components/Search");

export default {
  name: "Appbar",

  data: () => ({
    unreadChats: 0,
  }),

  apollo: {
    $subscribe: {
      tags: {
        query: UnreadChats,
        variables() {
          return {
            profileId: this.profileId,
          };
        },
        result({ data }) {
          this.$nextTick(() => {
            this.unreadChats = data.unreadChats.count;
          });
        },
      },
    },
  },

  watch: {
    currentUser () {
      if (this.currentUser) this.unreadChats = this.currentUser.selectedProfile.unreadChats;
    }
  },

  created () {
    if (this.currentUser) this.unreadChats = this.currentUser.selectedProfile.unreadChats;
  },

  computed: {
    ...mapGetters(["url", "isLoggedIn", "currentUser", "profileId"]),
  },

  methods: {
    logout () {
      localStorage.removeItem('auth_token')
      localStorage.removeItem('order_token')
      this.$router.go()
    },
  },

  components: { Dialog, Notifications, Search },
};
</script>

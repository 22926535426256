import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: {
      requireLogin: true,
      home: true
    }
  },
  {
    path: "/feria/:id",
    name: "fair",
    component: () =>
      import(/* webpackChunkName: "fair" */ "../views/fairs/PublicFair.vue"),
    meta: {
      public: true
    }
  },
  {
    path: "/success/:orderToken",
    name: "Success",
    component: () =>
      import(/* webpackChunkName: "Success" */ "@/views/Success.vue"),
    meta: {
      public: true
    }
  },
  {
    path: "/payku/confirm",
    name: "payku_confirm",
    component: () =>
      import(
        /* webpackChunkName: "Success" */ "@/views/checkout/payku/Confirm.vue"
      ),
    meta: {
      public: true
    }
  },
  {
    path: "/failure/:orderToken",
    name: "Failure",
    component: () =>
      import(/* webpackChunkName: "Failure" */ "@/views/checkout/Failure.vue"),
    meta: {
      public: true
    }
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      hideAppbar: true,
      hideBottom: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: "/forgot_password",
    name: "forgot_password",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/ForgotPassword.vue"),
    meta: {
      hideAppbar: true,
      hideBottom: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: "/signup",
    name: "signup",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/Signup.vue"),
    meta: {
      hideAppbar: true,
      hideBottom: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: "/explore",
    name: "explore",
    component: () =>
      import(/* webpackChunkName: "explore" */ "../views/Explore.vue"),
    meta: {
      requireLogin: true
    }
  },
  {
    path: "/mis_ferias/:id",
    name: "fairs",
    component: () =>
      import(/* webpackChunkName: "explore" */ "../views/fairs/Fair.vue"),
    meta: {
      requireLogin: true
    }
  },
  {
    path: "/chats/:id",
    name: "chat",
    component: () =>
      import(/* webpackChunkName: "chats" */ "../views/social/Chat.vue"),
    meta: {
      requireLogin: true
    }
  },
  {
    path: "/chats",
    name: "chats",
    component: () =>
      import(/* webpackChunkName: "chats" */ "../views/social/Chats.vue"),
    meta: {
      requireLogin: true
    }
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/Checkout.vue"),
    meta: {
      title: "Mi pedido"
    }
  },
  {
    path: "/p/:slug",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profiles" */ "../views/Profile.vue"),
    meta: {
      public: true
    }
  },
  // PF admin
  {
    path: "/partnersfans/fairs",
    name: "pf_admin_fairs",
    component: function() {
      return import(
        /* webpackChunkName: "PFAdminFairs" */ "@/views/partners_fans/Fairs.vue"
      );
    },
    meta: {
      hideAppbar: true,
      requireLogin: true,
      hideBottom: true,
      admin: true
    }
  },
  {
    path: "/partnersfans",
    name: "pf_admin",
    component: function() {
      return import(
        /* webpackChunkName: "PFAdminHome" */ "@/views/partners_fans/Home.vue"
      );
    },
    meta: {
      owners: true,
      hideAppbar: true,
      requireLogin: true,
      hideBottom: true
    }
  },
  {
    path: "/partnersfans/commissions",
    name: "pf_admin_commissions",
    component: function() {
      return import(
        /* webpackChunkName: "PFAdminHome" */ "@/views/partners_fans/Commissions.vue"
      );
    },
    meta: {
      owners: true,
      hideAppbar: true,
      requireLogin: true,
      hideBottom: true
    }
  },
  {
    path: "/partnersfans/orders",
    name: "pf_admin_orders",
    component: function() {
      return import(
        /* webpackChunkName: "PFAdminHome" */ "@/views/partners_fans/Orders.vue"
      );
    },
    meta: {
      owners: true,
      hideAppbar: true,
      requireLogin: true,
      hideBottom: true
    }
  },
  // admin
  {
    path: "/admin/settings",
    name: "admin",
    component: function() {
      return import(
        /* webpackChunkName: "AdminHome" */ "@/views/admin/Home.vue"
      );
    },
    meta: {
      hideAppbar: true,
      requireLogin: true,
      hideBottom: true,
      admin: true
    }
  },
  {
    path: "/admin/fairs",
    name: "admin_fairs",
    component: function() {
      return import(
        /* webpackChunkName: "AdminFairs" */ "@/views/admin/Fairs.vue"
      );
    },
    meta: {
      hideAppbar: true,
      requireLogin: true,
      hideBottom: true,
      admin: true
    }
  },
  {
    path: "/admin/analytics",
    name: "admin_analytics",
    component: function() {
      return import(
        /* webpackChunkName: "AdminAnalytics" */ "@/views/admin/Analytics.vue"
      );
    },
    meta: {
      hideAppbar: true,
      requireLogin: true,
      hideBottom: true,
      admin: true
    }
  },
  {
    path: "/admin/products",
    name: "admin_products",
    component: function() {
      return import(
        /* webpackChunkName: "ProductList" */ "@/views/admin/products/Products.vue"
      );
    },
    meta: {
      hideAppbar: true,
      requireLogin: true,
      hideBottom: true,
      admin: true
    }
  },
  {
    path: "/admin/option_types",
    name: "admin_option_types",
    component: function() {
      return import(
        /* webpackChunkName: "OptionTypesList" */ "@/views/admin/OptionTypes.vue"
      );
    },
    meta: {
      hideAppbar: true,
      requireLogin: true,
      hideBottom: true,
      admin: true
    }
  },
  {
    path: "/admin/promotions",
    name: "admin_promotions",
    component: function() {
      return import(
        /* webpackChunkName: "AdminPromotions" */ "@/views/admin/Promotions.vue"
      );
    },
    meta: {
      hideAppbar: true,
      requireLogin: true,
      hideBottom: true,
      admin: true
    }
  },
  {
    path: "/admin/orders",
    name: "admin_orders",
    component: function() {
      return import(
        /* webpackChunkName: "OrderList" */ "@/views/admin/Orders.vue"
      );
    },
    meta: {
      hideAppbar: true,
      hideBottom: true,
      requireLogin: true,
      admin: true
    }
  },
  {
    path: "/admin/product/:id",
    name: "admin_product",
    component: function() {
      return import(
        /* webpackChunkName: "ProductDetail" */ "@/views/admin/products/Product.vue"
      );
    },
    meta: {
      hideAppbar: true,
      hideBottom: true,
      requireLogin: true,
      admin: true
    }
  },
  {
    path: "/admin/categories",
    name: "admin_categories",
    component: function() {
      return import(
        /* webpackChunkName: "AdminCategories" */ "@/views/admin/Categories.vue"
      );
    },
    meta: {
      hideAppbar: true,
      hideBottom: true,
      requireLogin: true,
      admin: true
    }
  },
  {
    path: "/admin/payment_methods",
    name: "admin_payment_methods",
    component: function() {
      return import(
        /* webpackChunkName: "AdminPaymentMethods" */ "@/views/admin/PaymentMethods.vue"
      );
    },
    meta: {
      hideAppbar: true,
      hideBottom: true,
      requireLogin: true,
      admin: true
    }
  },
  {
    path: "/admin/shipping_methods",
    name: "admin_shipping_methods",
    component: function() {
      return import(
        /* webpackChunkName: "AdminShippingMethods" */ "@/views/admin/ShippingMethods.vue"
      );
    },
    meta: {
      hideAppbar: true,
      hideBottom: true,
      admin: true,
      requireLogin: true
    }
  },
  { path: "/admin", redirect: "/admin/settings" }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            top: -5000,
            behavior: "smooth"
          });
        }, 500);
      });
    } else {
      if (savedPosition) {
        return savedPosition;
      }

      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  const requireLogin = to.matched.some(record => record.meta.requireLogin);
  const onlyWhenLoggedOut = to.matched.some(
    record => record.meta.onlyWhenLoggedOut
  );
  const loggedIn = !!localStorage.getItem("auth_token");

  if (requireLogin && !loggedIn) {
    return next({
      path: "/login",
      query: { redirect: to.fullPath } // Store the full path to redirect the user to after login
    });
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next("/");
  }

  next();
});

export default router;

import gql from 'graphql-tag'             

export const NewMessage = gql`
  subscription newMessage($chatId: ID!) {
    newMessage(chatId: $chatId) {
      message {
        id
        body
        sharableId
        sharableType
        profileId
        profilePicture
      }
    }
  }
`

export const DeletedMessage = gql`
  subscription deletedMessage($chatId: ID!) {
    deletedMessage(chatId: $chatId) {
      message {
        id
        body
        sharableId
        sharableType
        profileId
        profilePicture
      }
    }
  }
`

export const NewChat = gql`
  subscription newChat($profileId: ID!) {
    newChat(profileId: $profileId) {
      success
    }
  }
`

export const UnreadChats = gql`
  subscription unreadChats($profileId: ID!) {
    unreadChats(profileId: $profileId) {
      count
    }
  }
`

import gql from 'graphql-tag'
import { Store, Section, Category } from '@/graphql/fragments'

export const Analytics = gql`
  query store {
    store {
      id
      rating
      averageTime
      orders {
        createdAt
      }
      storeTracker {
        views
        orders
        pendingOrders
        completedOrders
        abandonedOrders
        dropoutState
        mostBoughtProduct {
          name
          sku
        }
        mostSeenProduct {
          name
          sku
        }
      }
    }
  }
`

export const ProductDetail = gql`
  query publicStore($slug: ID!) {
    publicStore(slug: $slug) {
      id
      name
      slug
      profile {
        id
        slug
        picture {
          url
        }
      }
      information {
        id
        fullAddress
        businessField
      }
    }
  }
`

export const PublicStore = gql`
  query publicStore($slug: ID!) {
    publicStore(slug: $slug) {
      id
      name
      slug
      description
      profileType
      rating
      aboutUs {
        body
        banner {
          url
        }
      }
      information {
        id
        fullAddress
        businessField
      }
    }
  }
`

export const StoreSections = gql`
  query storeSections {
    storeSections {
      ...${Section}
    }
  }
`

export const Stores = gql`
  query stores($search: SearchInput, $storeType: String) {
    stores(search: $search, storeType: $storeType) {
      id
      name
      slug
      description
      rating
      information {
        id
        fullAddress
        businessField
      }
    }
  }
`

export const Categories = gql`
  query stores {
    stores {
      information {
        id
        businessField
      }
    }
  }
`

export const Header = gql`
  query storeHeader($slug: ID!) {
    storeHeader(slug: $slug) {
      id
      bannerIds
      fullscreen
      productsType
      height
      banners {
        id
        imageUrl
        height
      }
      mobileBanners {
        id
        imageUrl
        height
      }
      products {
        id
        name
        displayPrice
        featured
        images {
          id
          thumbUrl
        }
      }
    }
  }
`

export const Sections = gql`
  query storeSections($slug: ID!) {
    storeSections(slug: $slug) {
      id
      banners {
        id
        imageUrl
      }
      categoryIds
      categories {
        ...${Category} 
      }
    }
  }
`

export const SearchStores = gql`
  query searchStores($search: String!, $limit: ID) {
    searchStores(search: $search, limit: $limit) {
      id
      name
      slug
      description
      profile {
        id
        slug
        picture {
          id
          thumbUrl
        }
      }
      information {
        id
        fullAddress
      }
    }
  } 
`

import router from '@/router/index'
import { TokenService } from '@/services/storageService'
import { apolloClient } from '@/graphql/apollo'
import { PublicStore } from '@/graphql/queries/stores'

export default {
  namespaced: true,
	state: {
		currentStore: null,
		url: process.env.VUE_APP_RAILS_URL,
	},
	getters: {
		currentStore: state => {
			return state.currentStore
		},
		url: state => {
			return state.url
		},
	},
	mutations: {
    setStore: (state, slug) => {
      if (slug) {
        apolloClient.query({
          query: PublicStore,
          variables: {
            slug: slug
          },
          fetchPolicy: 'no-cache'
        }).then( res => {
          state.currentStore = res.data.publicStore
          if (res.data.publicStore) {
            document.title = res.data.publicStore.preferences.title || 'Tienda'
          } else {
            document.title = process.env.VUE_APP_TITLE
          }
        })
      } else {
        state.currentStore = null
        document.title = process.env.VUE_APP_TITLE
      }
		},

    removeStore: (state) => {
      state.currentStore = null
    }
	}
}


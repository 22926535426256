import gql from 'graphql-tag'
import { Order as OrderFragment, OrderList } from '@/graphql/fragments'

export const Order = gql`
  query order($orderToken: ID) {
    order(orderToken: $orderToken) {
      ...${ OrderFragment }
    }
  } 
`

export const Orders = gql`
  query orders($root: Boolean, $admin: Boolean, $filters: OrderFilter, $search: String, $page: ID) {
    orders(root: $root, admin: $admin, filters: $filters, search: $search, page: $page) {
      ...${ OrderList }
    }
  } 
`

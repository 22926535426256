import Vue from 'vue'
import Vuex from 'vuex'
import user from "@/store/modules/user";
import store from "@/store/modules/store";
import order from "@/store/modules/order";
import { Capacitor } from '@capacitor/core';

Vue.use(Vuex);                 
      
export default new Vuex.Store({
  state: {
    isLoggedIn: !!localStorage.getItem("auth_token"),
    currentUser: null,
    url: process.env.VUE_APP_RAILS_URL,
    baseUrl: process.env.VUE_APP_BASE_URL,
    profileId: localStorage.getItem('prefetched_sub'),
    isNative: Capacitor.isNativePlatform(),
  },
  mutations: {
    login(state) {
      state.isLoggedIn = true
    },
    logout(state) {
      state.isLoggedIn = false;
    },
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
  },
  getters: {
    isLoggedIn: state => {
      return state.isLoggedIn
    },
    isNative: state => {
      return state.isNative
    },
    currentUser: state => {
      return state.currentUser
    },
    url: state => {
      return state.url
    },
    baseUrl: state => {
      return state.baseUrl
    },
    profileId: state => {
      return state.profileId
    }
  },
  actions: {
  },
  modules: {
    user,
    store, 
    order
  }
})

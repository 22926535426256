import Vue from 'vue'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'
import { ApolloLink } from 'apollo-link'

import ActionCable from 'actioncable';
import { ActionCableLink } from 'graphql-ruby-client';

import { createUploadLink } from 'apollo-upload-client'

const cable = ActionCable.createConsumer(process.env.VUE_APP_WS_URL)

const httpLink = ApolloLink.from([
  createUploadLink({
    uri: process.env.VUE_APP_GRAPHQL_ENDPOINT
  })
])

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(
    ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription'
  )
}

const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({cable}),
  httpLink
);

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem('auth_token')
  const lang = localStorage.getItem('lang')
  if (token) {
    operation.setContext({
      headers: {
        authorization: `${token}`,
        lang: lang,
      }
    })
  }

  return forward(operation)
})

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: authMiddleware.concat(link),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  subscriptions: true,
  persisting: false
})

// Install the Vue plugin
Vue.use(VueApollo)

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

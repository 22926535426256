const TOKEN_KEY = 'access_token'
const ORDER_TOKEN_KEY = 'order_token'
const LOCALE_I18N = 'es'

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 **/
const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY)
  },

  saveToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken)
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY)
  },
}

const OrderService = {
  getToken() {
    return localStorage.getItem(ORDER_TOKEN_KEY)
  },

  saveToken(orderToken) {
    localStorage.setItem(ORDER_TOKEN_KEY, orderToken)
  },

  removeToken() {
    localStorage.removeItem(ORDER_TOKEN_KEY)
  },

  getToken() {
    return localStorage.getItem(ORDER_TOKEN_KEY)
  },
}

const LocaleService = {
  getLocale() {
    if (!localStorage.getItem(LOCALE_I18N)) { this.saveLocale('es') }
    return localStorage.getItem(LOCALE_I18N)
  },

  saveLocale(locale) {
    localStorage.setItem(LOCALE_I18N, locale)
  }
}

export { TokenService, LocaleService, OrderService }

import gql from 'graphql-tag'

const PaymentMethod = `
  {
    id
    name
  }
`

const ShippingMethod = `
  {
    id
    name
    description
    toPay
    price
    active
    cover
  }
`

const Payment = `
  {
    id
    state
    total
    displayTotalAmount
    authorizationCode
    completedAt
    installments
    installmentsAmount
    lastDigits
    paymentMethod {
      ...${PaymentMethod}
    }
  }
`

const Address = `
  {
    id
    fullAddress
    lat
    lng
    fullname
    idNumber
    phone
    street
    number
    unit
    zipcode
    city
    state
    country
  }
`

const Price = `
  {
    id
    amount
    discount
    currency
    stringify
  }
`

const StockItem = `
  {
    id
    stock
    stockLocationId
  }
`

const OptionValue = `
  {
    id
    value
    optionType {
      id
      name
    }
  }
`

const VariantOptionValue = `
  {
    id
    optionValueId
    optionValue {
      ...${OptionValue}
    }
  }
`

const OptionType = `
  {
    id
    name
    optionValues {
      ...${OptionValue}
    }
  }
`

const ProductOptionType = `
  {
    id
    optionType {
      ...${OptionType}
    }
  }
`

const Image = `
  {
    id
    name
    size
    url
    thumbUrl
    smallThumbUrl
  }
`

const BaseVariant = `
  {
    id
    optionValueIds
    variantOptionValues {
      ...${VariantOptionValue},
      variant {
        id
        stock
        displayPrice
        images {
          ...${Image}
        }
      }
    }
    optionTypes {
      id
      name
    }
    optionValues {
      id
      value
      optionTypeId
    }
  }
`

const Variant = `
  {
    id
    name
    sku
    length
    height
    depth
    weight
    productId
    stock
    optionValueIds
    displayPrice
    images {
      ...${Image}
    }
    prices {
      ...${Price}
    }
    stockItems {
      ...${StockItem}
    }
    variantOptionValues {
      ...${VariantOptionValue},
      variant {
        id
        stock
        displayPrice
      }
    }
    optionTypes {
      id
      name
    }
    optionValues {
      id
      value
      optionTypeId
    }
  }
`

const LineItem = `
  {
    id
    quantity
    totalStock
    displayAmount
    variant {
      ...${Variant}          
    }
    product {
      id
      images {
        id
        thumbUrl
      }
    }
  }
`

// Refactor this and split in multiple fragments
// Maybe one with basic_info, another with options, variants, and so
// Poor performance backend-side due to this
const Product = `
  {
    id
    active
    canShip
    enabled
    name
    description
    sku
    favoritesCount
    productType
    price
    displayPrice
    fullPrice
    displayFullPrice
    totalStock
    categoryIds
    createdAt
    storeId
    featured
    rating
    priceTrackers {
      id
      amount
      createdAt
    }
    optionTypes {
      ...${OptionType}
    }
    master {
      ...${Variant}  
    }
    images {
      ...${Image}
    }
  }
`

const BaseProduct = `
  {
    id
    name
    displayPrice
    displayFullPrice
    description
    featured
    productType
    createdAt
    canDelete
    enabled
    active
    canShip
    images {
      ...${Image}
    }
    store {
      id
      name
      slug
    }
  }
`

const CategoryChildren = `
  {
    id
    name
    slug
    treeLevel
    fullName
    breadcrumb
    description
  }
`

const BaseCategory = `
  {
    ...${ CategoryChildren }
    children {
      ...${ CategoryChildren } 
      children {
        ...${ CategoryChildren } 
      }
    }
  }
`

const Category = `
  {
    ...${ BaseCategory }
    products {
      ...${ BaseProduct }
    }
  }
`

const StoreInformation = `{
    id
    businessField
    fullAddress
    address {
      ...${Address}
    }
    latitude
    longitude
  }
`

const StorePreferences = `{
    id
    title
    metaDescription
    metaKeywords
    primaryColor
    secondaryColor
    ordersPrefix
  }
`

const Section = `
  {
    id
    banners {
      id
      linkableId
      height
      imageUrl
      color
      body
      title
    }
    sectionType
    categoryIds
    categories {
      ...${Category} 
    }
  }
`

const Store = `
  {
    id
    name
    slug
    email
    description
    favoritesCount
    profileType
    transbankCodeApplied
    aboutUs {
      id
      body
      banner {
        id
        url
      }
    }
    address {
      ...${Address}
    }
    information {
      ...${StoreInformation}
    }
    header {
      id
      bannerIds
      height
      fullscreen
      productsType
      banners {
        id
        linkableId
        height
        body
        imageUrl
        color
        title
      }
      mobileBanners {
        id
        linkableId
        height
        body
        imageUrl
        color
        title
      }
      products {
        ...${BaseProduct}
      }
    }
    sections {
      ...${Section}
    }
    preferences {
      ...${StorePreferences}
    }
  }
`

const Order = `
  {
    id
    total
    lineItemsAmount
    displayLineItemsAmount
    orderToken
    createdAt
    number
    email
    displayTotalAmount
    coupon
    displayPromotionsAmount
    displayDiffAmount
    state
    storeId
    shipments {
      id
      name
      toPay
      displayAmount
      tracking
      ticketUrl
      number
      state
      details
    }
    children {
      id
      storeId
      orderToken
      lineItemsAmount
      displayLineItemsAmount
      displayPromotionsAmount
      displayDiffAmount
      coupon
      total
      createdAt
      state
      shippingMethods {
        id
        name
        preferences
        description
        toPay
        price
        storeId
      }
      store {
        ...${Store}
      }
      lineItems {
        ...${LineItem}
      }
      shipments {
        id
        displayAmount
        tracking
        ticketUrl
        state
        details
        number
        name
        toPay
      }
      payments {
        ...${Payment}
      }
    }
    lineItems {
      ...${LineItem}
    }
    address {
      ...${Address}
    }
    payments {
      ...${Payment}
    }
    store {
      id
      name
      address {
        ...${Address}
      }
      information {
        id
        fullAddress
      }
    }
  }
`

const OrderList = `
  {
    pages
    orders {
      id
      total
      orderToken
      number
      email
      displayTotalAmount
      state
      createdAt
      lineItems {
        id
      }
    }
  }
`

const Profile = `
  {
    id
    active
    disabled
    name
    slug
    description
    followerIds
    followingIds
    fanFollowingIds
    followersCount
    followingCount
    fanFollowingCount
    profilableType
    profilableId
    userId
    postIds
    productIds
    ordersCount
    salesCount
    picture {
      url
      thumbUrl
    }
  }
`

const Chat = `
  {
    id
    lastMessage
    participants {
      read
      profileId
    }
    profiles {
      id
      name
    }
  }
`

const Message = `
  {
    id
    body
    sharableId
    sharableType
    profileId
    profilePicture
  }
`

const Comment = `
  {
    id
    body
    postId
    likedBy
    replyingTo
    profile {
      id
      name
      slug
      profilableType
      picture {
        url
        thumbUrl
      }
    }
  }
`

export { Product, BaseVariant, Variant, Price, StockItem, Order, OrderList, Address, LineItem, PaymentMethod, ShippingMethod, Store, Category, Section, BaseProduct, BaseCategory, Profile, Chat, Message, Comment, OptionType, OptionValue }

import gql from 'graphql-tag'
import { Store } from '@/graphql/fragments'

export const EditUser = gql`
  mutation editUser($input: EditUserInput!) {
    editUser(input: $input) {
      clientMutationId
      user {
        email
        id
      }
    }
  }
`

export const DisableAccount = gql`
  mutation disableAccount($input: DisableAccountInput!) {
    disableAccount(input: $input) {
      clientMutationId
      success
    }
  }
`

export const UpdateCurrentSubscription = gql`
  mutation updateCurrentSubscription($input: UpdateCurrentSubscriptionInput!) {
    updateCurrentSubscription(input: $input) {
      clientMutationId
      success
    }
  }
`

export const UpdatePassword = gql`
  mutation updatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      clientMutationId
      success
    }
  }
`

export const CreateUser = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      token
      user {
        firstSignIn
        email
        id
        selectedProfile {
          slug
          picture {
            url
          }
        }
      }
    }
  }
`

export const SignIn = gql`
  mutation signIn($input: SignInUserInput!) {
    signIn(input: $input) {
      clientMutationId
      token
      user {
        id
        email
        firstSignIn
        owner
        reportedCommentsIds
        reportedPostsIds
        reportedProfilesIds
        reportedProductsIds
        onesignalSubscribed
        onesignalStatus
        onesignalId
        profiles {
          id
          name
          profilableType
          picture {
            thumbUrl
          }
        }
        selectedProfile {
          id
          name
          userId
          profilableType
          profilableId
          slug
          unreadChats
          unreadNotifications
          active
          siblingActive
          picture {
            thumbUrl
            url
          }
        }
        store {
          ...${Store}
        }
      }
    }
  }
`

export const SignoutUser = gql`
  mutation signOut($input: SignOutInput!) {
    signOut(input: $input) {
      success
    }
  }
`

export const UpdateFirstSignIn = gql`
  mutation updateFirstSignIn($input: UpdateFirstSignInInput!) {
    updateFirstSignIn(input: $input) {
      success
    }
  }
`

export const ChangeSelectedProfile = gql`
  mutation changeSelectedProfile($input: ChangeSelectedProfileInput!) {
    changeSelectedProfile(input: $input) {
      success
    }
  }
`

<template>
  <div
    v-if="currentUser"
    >
    <v-dialog
      content-class="align-self-end ma-0 rounded-b-0 rounded-t-xl"
      transition="dialog-bottom-transition"
      v-model="dialog"
      >
      <v-card
        class="rounded-b-0"
        >
        <v-dialog
          v-model="modal"
          >
          <v-card>
            <v-card-title
              class="justify-center"
              >
              ¡Así que quieres ser <span class="ml-1 font-weight-medium secondary--text">Partner</span>!
            </v-card-title>
            <v-card-text
              class="text-center"
              >
            <create-store
              text="Tranquilo, sólo te tomará un segundo y estarás dentro, nada más necesitamos saber un poco más sobre tu tienda."
              @next="$router.go()"
              ></create-store>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-card-text
          class="pa-0"
          >
          <v-list>
            <v-list-item-group
              color="primary"
              >
              <v-list-item
                v-for="profile in currentUser.profiles"
                v-if="profile.id != currentUser.selectedProfile.id"
                :key="profile.id"
                @click="changeProfile(profile.id)"
                >
                <v-list-item-avatar>
                  <v-img
                    v-if="profile.picture"
                    :src="url + profile.picture.thumbUrl"
                    ></v-img>
                  <v-icon
                    v-else
                    >
                    {{ profile.profilableType == 'Store' ? 'mdi-storefront' : 'mdi-account-circle' }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ profile.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="currentUser.profiles.length < 3"
                @click="modal = true"
                >
                <v-list-item-avatar>
                  <v-icon
                    color="primary"
                    >
                    mdi-storefront
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="primary--text"
                    >
                    <template
                      v-if="currentUser.profiles.length == 1"
                      >
                      ¡Quiero ser <span class="secondary--text font-weight-medium">Partner</span>!
                    </template>

                    <template
                      v-else
                      >
                      ¡Quiero sumar un <span class="secondary--text font-weight-medium">Partner</span>!
                    </template>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                @click="signout"
                >
                <v-list-item-avatar>
                  <v-icon>
                    mdi-account-off
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    Cerrar sesión
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-bottom-navigation
      app
      height="48"
      class="elevation-0"
      style="border-top: 1px solid #d2d2d2 !important;"
      v-if="currentUser"
      >
      <v-btn-toggle
        color="secondary"
        mandatory
        tile
        group
        class="fill-width"
        >
        <v-btn
          to="/"
          >
          <v-icon>
            mdi-home
          </v-icon>
        </v-btn>
        <v-btn
          to="/explore"
          >
          <v-icon>
            mdi-compass
          </v-icon>
        </v-btn>
        <Dialog></Dialog>
        <v-btn
          to="/checkout"
          >
          <v-icon>
            mdi-cart
          </v-icon>
        </v-btn>
        <v-btn
          v-hammer:touchhold="openDialog"
          :to="'/p/' + currentUser.selectedProfile.slug"
          >
          <v-avatar
            size="25"
            >
            <v-img
              :src="url + currentUser.selectedProfile.picture.thumbUrl"
              v-if="currentUser.selectedProfile.picture"
              ></v-img>
            <v-icon
              v-else
              >
              mdi-account-circle
            </v-icon>
          </v-avatar>
        </v-btn>
      </v-btn-toggle>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ChangeSelectedProfile } from '@/graphql/mutations/users'

const Dialog = () => import('@/components/posts/Create')
const CreateStore = () => import('@/components/shared/onboarding/Store')

export default {
  data: () => ({
    dialog: false,
    modal: false,
    loading: false
  }),

  computed: {
    ...mapGetters(['currentUser', 'url'])
  },

  components: {
    Dialog, CreateStore
  },

  methods: {
    openDialog () {
      this.dialog = true
    },

    signout () {
      localStorage.removeItem('auth_token')
      localStorage.removeItem('order_token')
      this.$router.go()
    },

    changeProfile (id) {
      this.loading = true

      this.$apollo.mutate({
        mutation: ChangeSelectedProfile,
        variables: {
          input: {
            id: id
          }
        }
      }).then ( res => {
        this.$router.go()
      })
    }
  }
}
</script>

import { TokenService } from '@/services/storageService'
import { apolloClient } from '@/graphql/apollo'
import { SignIn } from '@/graphql/mutations/users'

export default {
  namespaced: true,
	state: {
		isLoggedIn: !!TokenService.getToken(),
		currentUser: null,
		url: process.env.VUE_APP_RAILS_URL,
		tempUser:{
      guest: true,
      profileAttributes: {
				fullname: '',
				idType: 'DNI',
				idNumber: '',
				address: '',
				phone: '',
				email: ''
			}
		},
	},
	actions: {
		fetchTempUser({commit}, tempUser){
			commit('setTempUser', tempUser)
		}
	},
	getters: {
		isLoggedIn: state => {
			return state.isLoggedIn
		},
		currentUser: state => {
			return state.currentUser
		},
		url: state => {
			return state.url
		},
		tempUser: state => {
			return state.tempUser
		}
	},
	mutations: {
		login (state) {
			state.isLoggedIn = true
		},
		logout (state) {
			state.isLoggedIn = false;
		},
		setCurrentUser (state, user) {
			state.currentUser = user;
		},
		async signIn (state) {
			if (state.isLoggedIn) {
				await apolloClient.mutate({
					mutation: SignIn,
					variables: {
						input: {
							token: TokenService.getToken()
						}
					}
				}).then(res => {
					let data = res.data.signIn
					if (data.token) {
						TokenService.saveToken(data.token)
						state.currentUser = data.user
					} else {
						TokenService.removeToken()
					}
				}).catch(errors => {
					TokenService.removeToken()
				})
			}
		},
    setTempUserProfile (state, profile) {
      state.tempUser.profileAttributes = profile 
    },
    resetTempUserProfile (state) {
      state.tempUser.profileAttributes = {
        fullname: '',
        idType: 'DNI',
        idNumber: state.tempUser.profileAttributes.idNumber,
        address: '',
        phone: '',
        email: ''
      }
    },
		//Form persistent handlers
		updateIdNumber(state, idNumber) {
			state.tempUser.profileAttributes.idNumber = idNumber
		},
		updateIdtype(state, idType) {
			state.tempUser.profileAttributes.idtype = idType
		},
		updatefullName(state, fullName) {
			state.tempUser.profileAttributes.fullname = fullName
		},
		updateAddress(state, address) {
			state.tempUser.profileAttributes.address = address
		},
		updatePhone(state, phone) {
			state.tempUser.profileAttributes.phone = phone
		},
		updateEmail(state, email) {
			state.tempUser.profileAttributes.email = email
    },
    updatePassword(state, password) {
			state.tempUser.credentials.password = password
		},

	}
}

